import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FeedsQueryKeys } from './feeds.keys';
import { PostInterface } from '../../app-schemas/posts/post.schema';
import apiClient from '../ApiClient';

// API call to delete a feed post by its ID
const deletePostById = async (id: number): Promise<any> => {
  return await apiClient.delete('post/' + id);
};

export const useDeleteFeedById = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePostById,

    onMutate: async (post_id: number) => {
      // Cancel ongoing fetches to prevent overriding the optimistic update
      await queryClient.cancelQueries({ queryKey: FeedsQueryKeys.all });

      // Retrieve the current cached data for the feed
      const previousData: any = queryClient.getQueryData(FeedsQueryKeys.all);
      if (!previousData) return { previousData };

      // Optimistically update the post reactions
      const updatedPages = previousData?.pages.map((page: any) => {
        const updatedPosts = page?.data.filter((post: PostInterface) => post.post_id !== post_id);

        return { ...page, data: updatedPosts };
      });

      const newPagesData = {
        ...previousData,
        pages: updatedPages,
      };

      queryClient.setQueryData(FeedsQueryKeys.all, newPagesData);

      // Return context with previous data in case of rollback
      return { previousData };
    },
    onError: (err, _, context?: any) => {
      // Rollback to the previous data on error
      if (context?.previousData) {
        queryClient.setQueryData(FeedsQueryKeys.all, context.previousData);
      }
    },
  });
};
