import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import queryString from 'query-string';
import { storeToken, storeUser } from '../../app-utils';
import auth from '../../api-services/auth/authentication.services';
import { RES_MSSG } from '../../api-services/ConstMssg';
import { SITELINK } from '../../app-routes/Links';
import { MembersLoginResponse } from '../../app-schemas/authentication.schema';

const GoogleCallback = () => {
  const navigate = useNavigate();

  const handleLoginSuccess = (res: MembersLoginResponse) => {
    const responseData = res;
    const userInfo = responseData.user;

    storeToken(responseData.data);
    storeUser(userInfo);

    if (userInfo.is_onboarded) {
      const loginRedirectedFromUrl = localStorage.getItem('loginRedirectedFromUrl');
      localStorage.removeItem('loginRedirectedFromUrl');
      navigate(loginRedirectedFromUrl ?? SITELINK.HOME);
    } else {
      navigate(SITELINK.PROFILE_SETUP);
    }
  };

  const GoogleLogin = async (access_token: any) => {
    // pageLoader(true);

    try {
      const res = await auth.GooglelLogin(access_token);

      if (res?.data?.message === RES_MSSG.SUCCESS) {
        handleLoginSuccess(res.data);
      } else {
        navigate(SITELINK.LOGIN);
      }
    } catch (error) {
      navigate(SITELINK.LOGIN);
    } finally {
      // pageLoader();
    }
  };

  useEffect(() => {
    const { access_token } = queryString.parse(window.location.hash);

    if (access_token) {
      GoogleLogin(access_token);
    } else {
      navigate('/login');
    }

    // eslint-disable-next-line
  }, [navigate]);

  return <></>;
};

export default GoogleCallback;
