import apiClient from '../ApiClient';

// Updated the function to accept pagination parameters
export const fetchFeeds = async ({ pageParam = 1 }: { pageParam: number }): Promise<any> => {
  const res = await apiClient.get('posts', {
    params: {
      page: pageParam,
    },
  });

  return {
    data: res.data?.data ?? [],
    totalPages: res.data?.pagination?.last_page ?? 0,
    totalItems: res.data?.pagination?.per_page ?? 0,
    currentPage: res.data?.pagination?.current_page ?? 0,
  };
};