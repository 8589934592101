import AppLayout from '../../layouts/AppLayout';
import { LuArrowLeft } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import { SITELINK } from '../../app-routes/Links';
import { useState } from 'react';
import { useCreateCommunity } from '../../api-services/community/use-create-community';
import ButtonComponent from '../../components/ButtonComponent';
// main-component-ui
const CreateCommunity = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [communityName, setCommunityName] = useState('');
  const [communityDescription, setCommunityDescription] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [tags, setTags] = useState<string[]>(['']);

  const navigate = useNavigate();
  const createNewCommunity = useCreateCommunity();

  const onSubmit = async (data: any) => {
    createNewCommunity.mutate(data);
  };

  if (createNewCommunity.isSuccess) {
    setLoading(false);
    navigate(`${SITELINK.COMMUNITIES}`);
  }

  const handleAddInput = () => {
    setTags([...tags, '']);
  };

  const handleRemoveInput = (index: number) => {
    if (tags.length > 1) {
      const newTags = tags.filter((_, i) => i !== index);
      setTags(newTags);
    }
  };

  const handleInputChange = (index: number, value: string) => {
    const newTags = tags.map((input, i) => (i === index ? value : input));
    setTags(newTags);
  };

  const handleUpload = (value: FileList | null) => {
    if (value !== null && value.length > 0) {
      setFile(value[0]);
    }
  };

  const appendArrayToFormData = (formData: FormData, key: string, array: number[] | string[]) => {
    array.forEach((value) => formData.append(key, value.toString()));
  };

  const addCommunity = () => {
    if (file !== null) {
      setLoading(true);
      const formData = new FormData();
      appendArrayToFormData(formData, 'community_tags[]', tags);
      formData.append('community_banner', file);
      formData.append('community_name', communityName);
      formData.append('community_description', communityDescription);
      onSubmit(formData);
    } else {
      console.log('res');
    }
  };
  return (
    <AppLayout title="Catapot - Create New Community">
      <div className="flex justify-between px-3 py-3  bg-white text-custom-600">
        <div className="flex gap-4">
          <Link to={SITELINK.COMMUNITIES} className="font-semibold text-sm flex mt-[5px]">
            <LuArrowLeft className="text-xl" />
          </Link>

          <p className="mt-1 text-sm font-medium">Create Community</p>
        </div>
      </div>
      <div className="composer-area bg-white">
        <div className=" pb-4 pt-0 sm:pb-4">
          <div>
            <div className="mt-0 sm:ml-0 sm:mt-0 sm:text-left">
              <div className="sm:mx-auto sm:w-full ">
                <form
                  className="space-y-3"
                  onSubmit={(e) => {
                    e.preventDefault();

                    addCommunity();
                  }}
                >
                  <div className="flex-1 rounded-lg px-4 pb-4 pt-4">
                    <div className="w-full">
                      <div>
                        <label
                          htmlFor="name"
                          className="mb-3 block text-xs font-bold text-gray-900"
                        >
                          Name
                        </label>
                        <div className="mt-2">
                          <input
                            name="firstName"
                            type="text"
                            value={communityName}
                            onChange={(e) => setCommunityName(e.target.value)}
                            required
                            className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-4 text-sm outline-2 placeholder:text-gray-500"
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="city"
                          className="mb-3 mt-5 block text-xs font-bold text-gray-900"
                        >
                          Description
                        </label>
                        <div className="mt-2">
                          <input
                            name="description"
                            type="text"
                            value={communityDescription}
                            onChange={(e) => setCommunityDescription(e.target.value)}
                            required
                            className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-4 text-sm outline-2 placeholder:text-gray-500"
                          />
                        </div>
                      </div>
                      <div className="w-full mt-2">
                        <div>
                          {tags.map((tag, index) => (
                            <div key={index} className="mt-2">
                              <label
                                htmlFor="tag"
                                className="mb-3 mt-5 block text-xs font-bold text-gray-900"
                              >
                                Tag {index + 1}
                              </label>
                              <input
                                type="text"
                                value={tag}
                                className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-4 text-sm outline-2 placeholder:text-gray-500"
                                onChange={(e) => handleInputChange(index, e.target.value)}
                              />
                              <div className="flex justify-between mt-2">
                                {tags.length !== 1 && (
                                  <button
                                    type="button"
                                    className="inline-flex  justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm  w-auto"
                                    onClick={() => handleRemoveInput(index)}
                                  >
                                    Remove Tag
                                  </button>
                                )}
                                {index === tags.length - 1 && (
                                  <button
                                    type="button"
                                    className="inline-flex  justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm w-auto"
                                    onClick={handleAddInput}
                                  >
                                    Add More Tag
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="w-full mt-2">
                        <label
                          htmlFor="city"
                          className="mb-3 mt-5 block text-xs font-bold text-gray-900"
                        >
                          Banner
                        </label>
                        <label className="block  w-full ">
                          <span className="sr-only">Choose Banner</span>
                          <input
                            type="file"
                            accept=".svg, .png, .jpeg, .jpg"
                            className="block w-full border border-gray-200 rounded-lg  text-sm text-gray-500 file:mr-4 file:py-[10px] file:px-4 file:rounded-none file:border-0 file:text-sm file:font-semibold file:bg-gray-50 file:text-gray-900 hover:file:bg-custom-600 hover:file:text-white"
                            onChange={(e) => handleUpload(e.currentTarget.files)}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ButtonComponent
            type="button"
            onClick={() => addCommunity()}
            disabled={loading}
            loading={loading}
            className="text-[13px] flex py-1 gap-1 mt-12 w-full h-[50px] justify-center bg-custom-600 text-white rounded-lg px-5 border font-medium"
          >
            Create
          </ButtonComponent>
        </div>
      </div>
    </AppLayout>
  );
};

export default CreateCommunity;
