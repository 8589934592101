import React, { useEffect, useState } from 'react';
import apiClient from '../api-services/ApiClient';

interface LinkPreviewProps {
  url: string;
}

interface Metadata {
  title: string;
  description: string;
  image?: string;
}

const LinkPreview: React.FC<LinkPreviewProps> = ({ url }) => {
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (url) {
      fetchMetadata(url);
    }
  }, [url]);

  const fetchMetadata = async (url: string): Promise<void> => {
    const urlPattern = /(https?:\/\/[^\s]+)/;
    const match = url.match(urlPattern);

    const new_url = match ? match[0] : null

    if(!new_url){
        return;
    }

    setLoading(true);
    try {
      const response = await apiClient.get<Metadata>(
        `fetch-meta-data?url=${encodeURIComponent(new_url)}`,
      );
      setMetadata(response.data);
    } catch (error) {
      console.error('Error fetching metadata:', error);
      setMetadata(null);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <p></p>;

  return metadata ? (
    <div className="link-preview">
      <h3 className="p-2 text-xs font-semibold bg-gray-200 rounded-t-lg">{metadata?.title}</h3>
      {/* <p>{metadata.description}</p> */}
      {metadata.image && <img src={metadata.image} alt="Link preview" />}
      <p className="mt-2 mb-3 text-semibold text-xs break-all text-blue-600 leading-0">
        <a href={url} target="blank">
          {url}
        </a>
      </p>
    </div>
  ) : (
    <p></p>
  );
};

export default LinkPreview;
