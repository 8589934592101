import { LuArrowLeft, LuPause, LuPlay, LuTrash2, LuX } from 'react-icons/lu';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SITELINK } from '../../app-routes/Links';
import { useUserCommunities } from '../../api-services/community/useUserCommunities';

import { MultiSelect, Option } from 'react-multi-select-component';
import feeds_api from '../../api-services/feeds/feeds.services';
import { useEffect, useState, useRef } from 'react';
import { LuImage, LuMic } from 'react-icons/lu';
import { Community } from '../../app-schemas/community/community.interface';
import { getFirstLetters, getUser, tinyAlert, truncateText } from '../../app-utils';
import { UserInterface } from '../../app-schemas/profile.schema';
import { Verify } from '../../assets/custom-icons';
import ButtonComponent from '../../components/ButtonComponent';
import Modal from '../../components/modal';
// import { ReactMediaRecorder } from 'react-media-recorder';
// import WhatsAppVoiceNote from './home-components/WhatsAppVoiceNote';

// main-component-ui
const CommunityComposer = () => {
  const { id } = useParams();

  document.body.classList.remove('overflow-hidden');
  const user: UserInterface = getUser();
  const navigate = useNavigate();

  // component states
  const [openModal, setOpenModal] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [userCommunitiesOptions, setUserCommunitiesOptions] = useState<Option[]>([]);
  const [communityID, setCommunityID] = useState<Option[]>([]);
  const [selectedCommunities, setSelectedCommunities] = useState<string[]>([id ?? '']);
  const [textContent, setTextContent] = useState<string>('');
  const [isRecording, setIsRecording] = useState(false);
  // const [audio, setAudio] = useState<any>(null);

  const [audioBlob, setAudio] = useState<string | Blob>('');
  const [isPaused, setIsPaused] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const startTimeRef = useRef<number | null>(null);
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const [multipleFile, setMultipleFile] = useState<File[] | null>(null);
  const [errmessage, setErrmessage] = useState('');
  const [errmessageII, setErrmessageII] = useState('');
  const [hashtag, setHashtag] = useState('');
  const [images, setImages] = useState<string[]>([]); // State to store image URLs

  // react-query area
  const userCommunities = useUserCommunities();

  /* side Effect area */
  useEffect(() => {
    if (userCommunities.isSuccess) {
      const options = userCommunities?.data?.data?.data?.map((option: Community) => ({
        label: `${option.name}`,
        description: `${option.city}, ${option.country}`,
        value: option.id,
      }));

      setUserCommunitiesOptions(options);
      setCommunityID(options);
      setSelectedCommunities([id ?? '']);
    }
  }, [userCommunities.isSuccess, userCommunities.data, id]);

  const handleRecord = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      tinyAlert('error', 'Your browser does not support audio recording.');
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorderRef.current = mediaRecorder;

    mediaRecorder.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, {
        type: 'audio/mpeg',
      });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioURL(audioUrl);
      setAudio(audioBlob);
      // sessionStorage.setItem('audioURL', audioBlob ?? "")
      audioChunksRef.current = [];
    };

    if (!isPaused) {
      setIsRecording(true);
      setRecordingTime(0);
      startTimeRef.current = Date.now();
    }

    mediaRecorder.start();
    setIsPaused(false);

    // Start recording time counter
    timerRef.current = setInterval(() => {
      setRecordingTime(Math.floor((Date.now() - startTimeRef.current!) / 1000));
    }, 1000);
  };
  const handleStop = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsPaused(false);

      if (audioPlayerRef.current) {
        audioPlayerRef.current.pause();
        audioPlayerRef.current.currentTime = 0;
      }
    }
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (audioURL) {
      console.log('Audio URL:', audioURL);
      // Send the audio file to a server or handle it as needed
    }
  };
  const handlePauseResume = () => {
    if (isRecording) {
      if (!isPaused) {
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current.pause();
          setIsPaused(true);
          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
          if (audioPlayerRef.current) {
            audioPlayerRef.current.pause();
            audioPlayerRef.current.currentTime = 0;
          }
        }
      } else {
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current.resume();
          setIsPaused(false);
          startTimeRef.current = Date.now() - recordingTime * 1000;
          timerRef.current = setInterval(() => {
            setRecordingTime(Math.floor((Date.now() - startTimeRef.current!) / 1000));
          }, 1000);
        }
      }
    }
  };
  const handleDiscard = () => {
    setAudioURL(null);
    setRecordingTime(0);
    setIsRecording(false);
    setIsPaused(false);
    sessionStorage.removeItem('audioURL');
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  // const createImage = (file: File) => {
  //   const reader = new FileReader();

  //   reader.onload = (e: ProgressEvent<FileReader>) => {
  //     if (e.target && typeof e.target.result === 'string') {
  //       const imgElement = document.createElement('img');
  //       imgElement.src = e.target?.result as string;
  //       imgElement.style.width = '100px'; // Adjust the size as needed
  //       imgElement.style.height = '100px'; // Adjust the size as needed
  //       imgElement.style.objectFit = 'cover';

  //       const imageContainer = document.getElementById('imageContainer');
  //       if (imageContainer) {
  //         imageContainer.appendChild(imgElement);
  //       }
  //     }
  //   };

  //   reader.readAsDataURL(file);
  // };

  // Function to handle the creation of an image
  const createImageII = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && typeof e.target.result === 'string') {
        // Add the image to the state
        setImages((prevImages) => [...prevImages, e.target?.result as string]);
      }
    };

    reader.readAsDataURL(file);
  };

  // Function to handle image deletion
  const deleteImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setMultipleFile(null);
  };

  const handleFileChange = (value: FileList | null) => {
    if (value !== null && value?.length > 0) {
      setMultipleFile(null);
      setImages([]);

      const filesArray = Array.from(value);
      setMultipleFile(filesArray);
      filesArray.forEach((file) => {
        // createImage(file);
        createImageII(file);
      });
    }
  };

  const handleContinue = (e: any) => {
    e.preventDefault();
    openModal
      ? document.body.classList.remove('overflow-hidden')
      : document.body.classList.add('overflow-hidden');

    textContent.length === 0
      ? setErrmessage('Kindly write something to continue')
      : setErrmessage('');
    textContent.length > 0 && setOpenModal(true);
  };
  const handleCloseModal = () => {
    document.body.classList.remove('overflow-hidden');
    setOpenModal(!openModal);
  };
  const handleCheckboxChange = (value: string) => {
    setSelectedCommunities((prevSelected) => {
      if (prevSelected.includes(value)) {
        // If the value is already selected, remove it
        return prevSelected.filter((item) => item !== value);
      } else {
        // Otherwise, add it to the selected items
        return [...prevSelected, value];
      }
    });
  };

  /** create post */
  const handleCreateNewPost = async () => {
    setErrmessageII('');
    if (selectedCommunities.length === 0) {
      setErrmessageII('Select at least one community');
      return;
    }

    setLoading(true);

    let data = {
      images: multipleFile,
      text_content: hashtag + ' ' + textContent,
      audio: audioBlob,
      selected_community: selectedCommunities,
    };

    try {
      await feeds_api.CreateNewFeed(data);
      navigate(`${SITELINK.COMMUNITY_ACTIVITIES}/${id}`);
    } catch (error) {
      tinyAlert('error', 'An error occured!');
    } finally {
      document.body.classList.remove('overflow-hidden');
      setLoading(false);
    }
  };

  return (
    <section className="bg-[#fff] h-[92vh]">
      <div className="fixed top-0 w-full z-50 shadow-lg">
        <div className="flex gap-4 relative px-2 py-3 bg-custom-600 text-white">
          <Link
            to={`${SITELINK.COMMUNITY_ACTIVITIES}/${id}`}
            className="font-semibold text-sm flex"
          >
            <LuArrowLeft className="text-2xl mt-[2px]" />
          </Link>

          <p className="text-lg font-medium">Create Post</p>
        </div>
      </div>

      {/* content area */}
      <div className="mt-[48px]">
        {/* profile dislay area */}
        <div className="flex gap-2 bg-white p-2 py-4 pb-2">
          {/* top lead area and post btn */}
          <Link to={SITELINK.MY_PROFILE} className="w-[50px] block">
            {user.profile_picture_url ? (
              <div className="flex-grow flex flex-col items-center">
                <div className="overflow-hidden flex justify-center">
                  <img
                    alt="profile"
                    src={user?.profile_picture_url}
                    className="object-cover rounded-full w-[40px] h-[40px] bg-gray-200 shadow-sm overflow-hidden"
                  />
                </div>
              </div>
            ) : (
              <span className="block w-[40px] h-[40px] bg-[#223f64] rounded-full border border-green-100">
                <h3 className="text-white text-[1.4rem] uppercase font-[600] relative top-0.5 left-2.5">
                  {getFirstLetters(user?.first_name ?? user?.username)}
                </h3>
              </span>
            )}
          </Link>

          <div className="text-sm">
            <span className="gap-1 flex">
              <span>{user?.username}</span>
              <span>{user?.is_verified && <img src={Verify} alt="" className="h-[12px]" />}</span>
            </span>
            <span className="block text-[10px] text-gray-600">{user?.email}</span>
          </div>
        </div>

        {/* input area */}
        <div className="composer-area px-3">
          <textarea
            value={textContent}
            placeholder="Have something to say?"
            className="text-sm font-[500] p-3 w-full border border-gray-300 shadow-sm rounded-lg"
            rows={5}
            onChange={(e) => setTextContent(e.target.value)}
          ></textarea>

          {/* Container for displaying images */}
          <div id="imageContainer" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {images.map((imageSrc, index) => (
              <div key={index} style={{ position: 'relative', width: '100px', height: '100px' }}>
                <img
                  src={imageSrc}
                  alt={`Uploaded ${index}`}
                  style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px' }}
                />
                {/* Delete icon */}
                <span
                  onClick={() => deleteImage(index)}
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    backgroundColor: '#bbb4b4',
                    color: 'red',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    padding: '3px 3px',
                  }}
                >
                  <LuX />
                </span>
              </div>
            ))}
          </div>

          {/* <div id="imageContainer" className="px-2 flex flex-wrap gap-2 mb-2 rounded-lg"></div> */}

          {audioURL && (
            <audio
              controls
              src={audioURL}
              className="bg-white w-full custom-audio-player mb-1"
            ></audio>
          )}
        </div>

        {/* upload attacment area */}
        <div className="flex justify-between px-5 mt-1">
          {/* add image area */}
          <label
            htmlFor="dropzone-file"
            className="text-sm flex py-1 px-4 rounded-full shadow-sm gap-1 cursor-pointer"
          >
            <LuImage className="text-lg text-custom-600" />{' '}
            <span className="text-custom-600 font-medium">Add image</span>
            <input
              id="dropzone-file"
              ref={fileInputRef}
              type="file"
              accept=".png, .jpeg, .jpg"
              onChange={(e) => handleFileChange(e.currentTarget.files)}
              className="hidden"
            />
          </label>

          <span className="border-r border-gray-400 border-1 m-1"></span>

          <div className="">
            <div className="controls flex items-center gap-3">
              {!isRecording && !audioURL && (
                <button
                  onClick={handleRecord}
                  className="text-sm flex py-1 px-4 rounded-full shadow-sm gap-1 cursor-pointer"
                  title="Start Recording"
                  disabled={isRecording}
                >
                  <LuMic className="text-lg text-custom-600" />
                  <span className="text-custom-600 font-medium">Voice Note </span>
                </button>
              )}
            </div>

            <div className="recording-status flex  w-[50%] md:w-full items-center gap-4">
              {(isRecording || audioURL) && (
                <button onClick={handleDiscard} title="Discard Recording" className="mt-[5px]">
                  <LuTrash2 className="text-lg text-red-700 font-bold" strokeWidth={1} />
                </button>
              )}

              {isRecording && (
                <span className="text-custom-600 font-medium">
                  {new Date(recordingTime * 1000).toISOString().substr(14, 5)}
                </span>
              )}

              {isRecording && (
                <button
                  onClick={handlePauseResume}
                  disabled={!isRecording}
                  title={isPaused ? 'Resume' : 'Pause'}
                >
                  {isPaused ? (
                    <LuMic className="text-custom-600 font-medium" strokeWidth={1} />
                  ) : (
                    <LuPause className="text-custom-600 font-medium" strokeWidth={1} />
                  )}
                </button>
              )}
              {isRecording && (
                <button onClick={handleStop} disabled={!isRecording} title="Stop Recording">
                  <LuPlay className="text-custom-600 font-medium" strokeWidth={1} />
                </button>
              )}
            </div>
          </div>
        </div>

        {/* hash-tags section */}
        <div className="px-5">
          <div className="pt-2 mt-2 flex gap-1 overflow-x-scroll pb-1">
            <button
              type="button"
              className={`text-xs flex py-2 gap-1 rounded-full px-4 border font-semibold whitespace-nowrap text-custom-600 ${
                hashtag === '#Jobs' ? 'bg-blue-700 text-white' : 'bg-gray-100'
              }`}
              onClick={() => setHashtag('#Jobs')}
            >
              #Jobs
            </button>

            <button
              type="button"
              className={`text-xs flex py-2 gap-1 rounded-full px-4 border font-semibold whitespace-nowrap text-custom-600 ${
                hashtag === '#Sharerides' ? 'bg-blue-700 text-white' : 'bg-gray-100'
              }`}
              onClick={() => setHashtag('#Sharerides')}
            >
              #Sharerides
            </button>

            <button
              type="button"
              className={`text-xs flex py-2 gap-1 rounded-full px-4 border font-semibold whitespace-nowrap text-custom-600 ${
                hashtag === '#Events' ? 'bg-blue-700 text-white' : 'bg-gray-100'
              }`}
              onClick={() => setHashtag('#Events')}
            >
              #Events
            </button>
          </div>
        </div>

        <div className="px-5">
          <div className="flex gap-1 overflow-x-scroll pb-4">
            <button
              type="button"
              className={`text-xs flex py-2 gap-1 rounded-full px-4 border font-semibold whitespace-nowrap text-custom-600 ${
                hashtag === '#Marketplace' ? 'bg-blue-700 text-white' : 'bg-gray-100'
              }`}
              onClick={() => setHashtag('#Marketplace')}
            >
              #Marketplace
            </button>

            <button
              type="button"
              className={`text-xs flex py-1 gap-1 bg-red-600 rounded-full px-2 border font-semibold whitespace-nowrap text-white`}
              onClick={() => setHashtag('')}
            >
              <span className="text-text-600 font-medium text-lg mt-[2px]">
                <LuTrash2 />
              </span>
            </button>
          </div>
        </div>

        <div className="px-5 mb-5">
          <p className="text-red-600 text-xs text-center">
            {textContent.length === 0 && errmessage}
          </p>
          <p className="text-red-600 text-xs text-center">
            {selectedCommunities.length === 0 && errmessageII}
          </p>
          <button
            type="button"
            // onClick={handleContinue}
            onClick={() => handleCreateNewPost()}
            className="w-full text-sm flex py-1 gap-1 justify-center disabled:bg-gray-400 text-white rounded-xl px-5 border font-medium py-3 mt-3 bg-blue-500 bg-custom-600"
            disabled={loading}
          >
            {loading ? 'Posting...' : 'Post'}
          </button>
        </div>

        <div className="px-5 mt-2 hidden">
          <p className="text-red-600 text-xs text-center">
            {textContent.length === 0 && errmessage}
          </p>
          <button
            type="button"
            onClick={handleContinue}
            className="w-full text-sm flex py-1 gap-1 mt-3 justify-center bg-custom-600 text-white rounded-xl px-5 border font-medium py-3"
          >
            Continue
          </button>
        </div>
      </div>

      <Modal isOpen={openModal} onClose={handleCloseModal}>
        <section className="p-3 w-[90vw]">
          <p className="hidden text-center font-semibold text-lg">Select communities</p>
          <p className="hidden text-center font-medium text-xs mt-1 text-gray-500 mb-3">
            Choose the communities you want this post to be published into.
          </p>

          <div className="px-2 py-2 border border-dotted border-gray-400 rounded-lg mb-3">
            <MultiSelect
              options={userCommunitiesOptions}
              value={communityID}
              onChange={setCommunityID}
              labelledBy="community-select-label"
              className="text-xs font-medium mb-2 hidden"
              overrideStrings={{
                allItemsAreSelected: 'Post to all communities',
                selectSomeItems: 'Select some community...',
                search: 'Search community',
                clearSearch: 'Clear search',
                clearSelected: 'Clear selected',
              }}
            />

            <div className="border p-2 mb-3 text-medium">
              <label className="border-b font-semibold text-lg w-full p-2 flex">
                Select communities
              </label>
              <div
                id="community"
                className="overflow-y-scroll h-[200px] p-1 mb-3 text-xs font-medium"
              >
                {userCommunitiesOptions.map((item: any, index: number) => (
                  <div key={index} className="border-t p-1">
                    <input
                      type="checkbox"
                      id={`community_${index}`}
                      name="community"
                      value={item.value}
                      onChange={() => handleCheckboxChange(item.value)}
                      checked={selectedCommunities.includes(item.value)}
                    />
                    <label className="pl-2" htmlFor={`community_${index}`}>
                      {truncateText(item.label, 25)}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <p className="text-red-600 text-xs text-center">
              {selectedCommunities.length === 0 && errmessageII}
            </p>

            <ButtonComponent
              type="button"
              onClick={() => handleCreateNewPost()}
              disabled={loading}
              loading={loading}
              className="text-sm flex py-1 gap-1 justify-center bg-custom-600 text-white rounded-lg px-5 border font-medium py-3"
            >
              Post
            </ButtonComponent>
          </div>
        </section>
      </Modal>
    </section>
  );
};

export default CommunityComposer;
