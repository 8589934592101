// import { useState, useEffect} from 'react';
import { LuArrowLeft } from 'react-icons/lu';
import { useFetchLikedFeeds } from '../../api-services/feeds/useFetchLikedFeeds';
import { Link } from 'react-router-dom';
import PostCard from './home-components/PostCard';
import { SITELINK } from '../../app-routes/Links';
import { Loader } from '../../assets/custom-icons';

// main-component-ui
const SavedPost = () => {
  document.body.classList.remove('overflow-hidden');
  const posts = useFetchLikedFeeds();


  /* Return && Render UI */
  return (
    <section className="bg-[#E1F0F8F7] h-[100vh]">
      {/* top lead area and post btn */}
      <div className="fixed top-0 w-full z-50 shadow-lg">
        <div className="flex gap-4 relative px-2 py-3 bg-custom-600 text-white">
          <Link to={SITELINK.HOME} className="font-semibold text-sm flex">
            <LuArrowLeft className="text-2xl mt-[2px]" />
          </Link>

          <p className="text-lg font-medium">Liked Posts</p>
        </div>
      </div>
      <div className="flex justify-between px-3 py-3 bg-white text-custom-600">
        <div className="flex gap-4">
          <Link to="/home" className="font-semibold text-sm flex mt-[5px]">
            <LuArrowLeft className="text-xl" />
          </Link>

          <p className="mt-1 text-sm font-medium">Liked Posts</p>
        </div>
      </div>

      
      {/* community feeds section */}
      <div className="mt-[50px] mb-20">
        {posts.isLoading ? (
          <div className="h-[90vh] flex justify-center items-center">
            <img src={Loader} alt="pre-loader" className="w-[20px] h-[20px]" />
          </div>
        ) : (
          <>
            {posts?.data?.length > 0 ? (
              posts?.data?.map((item: any, index: number) => (
                <PostCard key={index} index={index} post={item.post[0]} />
              ))
            ) : (
              <div className="relative top-[50px] py-3">
                <div className="text-center">
                  <p className="text-xs flex flex-col mt-24">No saved post available</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default SavedPost;
