import { useQuery } from '@tanstack/react-query';
import { FeedsQueryKeys } from './feeds.keys';
import apiClient from '../ApiClient';

const likedPosts = async (): Promise<any> => {
    const res = await apiClient.get('posts/liked');
    return res.data?.data;
};

export const useFetchLikedFeeds = () => {
  return useQuery({
    queryKey: FeedsQueryKeys.liked,
    queryFn: () => likedPosts(),
  });
};
