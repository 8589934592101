// read-more article on react-query optimistic-updates
// https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates

import { useMutation } from '@tanstack/react-query';
// import { FeedsQueryKeys } from './feeds.keys';
import apiClient from '../ApiClient';
// import { PostInterface } from '../../app-schemas/posts/post.schema';

const feedComments = async (id: number): Promise<any> => {
  // const data = { community_post_id: id };
  const res = await apiClient.get('comments?community_post_id='+id);
  return res.data;
};

export const useFeedComments = () => {
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: feedComments,
    onMutate: async (post_id) => {
      
    },
    onError: (err, newUser, context?: any) => {
      // Rollback to the previous data on error
      // if (context?.previousData) {
      //   queryClient.setQueryData(FeedsQueryKeys.all, context.previousData);
      // }
    },
  });
};
