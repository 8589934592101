import React, { useState, useEffect } from "react";
import Inbox from "./components/Inbox";
import Chat from "./components/Chat";
import { useLocation } from "react-router-dom";

const Messaging: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const lastChattedUserInfo =
    localStorage.getItem("lastChattedUserInfo") ?? null;

  const [selectedUser, setSelectedUser] = useState<{
    id: number;
    name: string;
    image: string;
  } | null>(null);

  useEffect(() => {
    if (lastChattedUserInfo) setSelectedUser(JSON.parse(lastChattedUserInfo));
  }, [lastChattedUserInfo]);

  const selectUser = (userId: number, name: string, image: string) => {
    const user = {
      id: userId,
      name: name,
      image: image,
    };
    setSelectedUser(user);
  };

  const backToInbox = () => {
    setSelectedUser(null);
  };

  return (
    <div className="bg-hc_F4EBFA">
      {selectedUser && currentPath !=='/messaging' ? (
        <Chat selectedUser={selectedUser} backToInbox={backToInbox} />
      ) : (
        <Inbox selectUser={selectUser} />
      )}
    </div>
  );
};

export default Messaging;
