// read-more article on react-query optimistic-updates
// https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates

import { useMutation } from '@tanstack/react-query';
// import { FeedsQueryKeys } from './feeds.keys';
import apiClient from '../ApiClient';
// import { PostInterface } from '../../app-schemas/posts/post.schema';

const postComment = async (id: number, commentMssg: string, parentId: any): Promise<any> => {
  console.log(parentId)
  let data:any = { community_post_id: id, content: commentMssg };
  if (parentId) data.parent_comment_id = parentId;

  const res = await apiClient.post('comment/create', data);
  return res.data;
};

export const usePostComment = () => {
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      commentMssg,
      parentId,
    }: {
      id: number;
      commentMssg: string;
      parentId: any;
    }) => postComment(id, commentMssg, parentId),
    onMutate: async ({ id, commentMssg, parentId }) => {
      console.log(id, commentMssg, parentId);
    },
    onError: (err, newUser, context?: any) => {
      // Rollback to the previous data on error
      // if (context?.previousData) {
      //   queryClient.setQueryData(FeedsQueryKeys.all, context.previousData);
      // }
    },
  });
};
