import React, { useEffect, useRef, useState } from 'react';
import { FaReply, FaX } from 'react-icons/fa6';
import { LuChevronLeft, LuSendHorizonal } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { UserProfileInterface } from '../../../app-schemas/user.interface';
import { LuMoreVertical } from 'react-icons/lu';
import {
  TEDropdown,
  TEDropdownToggle,
  TEDropdownMenu,
  TEDropdownItem,
  TERipple,
} from 'tw-elements-react';
import apiClient from '../../../api-services/ApiClient';
import { formatTimestamp, getUser, truncateText } from '../../../app-utils';
import { useConversation } from '../../../api-services/messaging/useConversation';
import { useSendMessage } from '../../../api-services/messaging/useSendMessage';
import { SITELINK } from '../../../app-routes/Links';
import Preloader from '../../../components/preloader/Preloader';
import { Avatar } from '../../../assets/custom-icons';

export interface selectedUserInterface {
  id: number;
  name: string;
  image: string;
}

const Chat: React.FC<{
  selectedUser: selectedUserInterface;
  backToInbox: () => void;
}> = ({ selectedUser, backToInbox }) => {
  // navigate
  const navigate = useNavigate();

  // collect page resources
  const user: UserProfileInterface = getUser();

  console.log(user);

  const { data: messages, error, isLoading } = useConversation(selectedUser.id);
  const { mutate: sendMessageMutation } = useSendMessage();

  // selectedUser
  const selectedUserStr = JSON.stringify(selectedUser);
  localStorage.setItem('lastChattedUserInfo', selectedUserStr);

  // page states
  const [replyTo, setReplyTo] = useState<number | null>(null);
  const [input, setInput] = useState('');
  const [touchStartX, setTouchStartX] = useState(0);
  const [dragOffset, setDragOffset] = useState(0);
  const [activeMessageId, setActiveMessageId] = useState<number | null>(null);
  const [showReplyIndicator, setShowReplyIndicator] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  // page refs
  const inputRef = useRef<HTMLInputElement | null>(null);
  const chatRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  const handleReply = (messageId: any) => {
    setReplyTo(messageId);
  };

  const focusInput = () => {
    const input = document.getElementById('myInput');
    if (input) {
      input.focus(); // Focus the input element
    }
  };

  const scrollDown = () => {
    const chatRefSection = document.getElementById('chatRef');
    if (chatRefSection) {
      chatRefSection.scrollTo({
        top: chatRefSection.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const sendMessage = (e: any) => {
    e.preventDefault();
    if (input.trim() === '') return;

    const message = {
      sender_id: user.id,
      receiver_id: selectedUser.id,
      message: input,
      reply_to: replyTo,
      created_at: new Date().toISOString(),
    };

    // Optimistically update with mutation
    sendMessageMutation({ receiver_id: selectedUser.id, message });

    setInput('');
    setReplyTo(null);
    scrollDown();
  };

  const endChat = async (e: any) => {
    e.preventDefault();
    let isConfirm = true;
    if (!isConfirm) return;

    try {
      setLoading(true);
      await apiClient.patch(`chat/close/${selectedUser.id}`);
      setLoading(false);
      navigate(SITELINK.CHAT);
      alert('chat ended');
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  // Handle swipe start
  const handleTouchStart = (e: React.TouchEvent, messageId: number) => {
    setTouchStartX(e.touches[0].clientX);
    setActiveMessageId(messageId);
    setShowReplyIndicator(true);
    focusInput();
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!activeMessageId) return;

    const touchX = e.touches[0].clientX;
    const deltaX = touchX - touchStartX;

    if (deltaX > 0) {
      setDragOffset(deltaX);
      if (deltaX > 30) setShowReplyIndicator(true);

      focusInput();
    }
  };

  const handleTouchEnd = () => {
    if (dragOffset > 50 && activeMessageId) {
      setReplyTo(activeMessageId);
    }

    setDragOffset(0);
    setActiveMessageId(null);
    setShowReplyIndicator(false);

    focusInput();
  };

  return (
    <div className="flex flex-col bg-hc_F4EBFA dark:bg-slate-700">
      {/* Chat Header */}
      <div className="bg-custom-600 text-white dark:bg-slate-800 p-2 px-2 flex items-center justify-between fixed top-[-1px] right-0 left-0 z-50">
        <div className="flex items-center">
          <button
            onClick={() => {
              navigate(SITELINK.CHAT);
              localStorage.removeItem('lastChattedUserInfo');
              backToInbox();
            }}
            className="mr-4 text-white"
          >
            <LuChevronLeft className="text-2xl" />
          </button>

          <div>
            <img
              src={!!selectedUser.image ? selectedUser.image : Avatar}
              className="h-[40px] w-[40px] rounded-full shadow border"
              alt="meme"
            />
          </div>

          <h1 className="text-lg font-medium ml-3">{selectedUser.name}</h1>
        </div>

        <div className="text-end pr-3">
          <TEDropdown className="flex justify-center">
            <TERipple rippleColor="light">
              <TEDropdownToggle className="p-2 rounded-full">
                <LuMoreVertical className="text-xl" />
              </TEDropdownToggle>
            </TERipple>

            <TEDropdownMenu>
              <TEDropdownItem>
                <button
                  onClick={endChat}
                  type="button"
                  className="block w-full min-w-[90px] cursor-pointer whitespace-nowrap bg-transparent px-4 py-2 text-sm text-left font-normal pointer-events-auto text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:bg-neutral-100 focus:bg-neutral-100 focus:text-neutral-800 focus:outline-none active:no-underline dark:text-neutral-200 dark:hover:bg-neutral-600 dark:focus:bg-neutral-600 dark:active:bg-neutral-600"
                >
                  End Chat
                </button>
              </TEDropdownItem>
            </TEDropdownMenu>
          </TEDropdown>
        </div>
      </div>

      {(isLoading || loading) && <Preloader loading={true} />}
      {error && (
        <div
          className="flex items-center justify-center"
          style={{
            height: '750px',
            overflowY: 'auto',
            padding: '10px',
          }}
        >
          {' '}
          Error loading conversation.
        </div>
      )}

      {/* Messages Area */}
      <div
        ref={chatRef}
        id="chatRef"
        style={{
          height: '100vh',
          overflowY: 'auto',
          padding: '10px',
        }}
      >
        <div className="pb-20 pt-12">
          {messages?.map((message: any, index: number) => {
            const isPreviousMessageBySameUser =
              index > 0 && messages[index - 1]?.sender_id === message?.sender_id;

            const currentDate = formatTimestamp(message?.created_at, 'date');
            const previousMessageDate =
              index > 0 ? formatTimestamp(messages[index - 1]?.created_at, 'date') : null;

            const dateToDisplay =
              index === 0 || previousMessageDate !== currentDate ? currentDate : null;

            return (
              <div key={index}>
                {dateToDisplay && (
                  <p className="w-full text-center text-xs pt-4 pb-4">
                    <span className="dark:bg-slate-800 p-2 px-4 rounded-xl bg-gray-200">
                      {dateToDisplay}
                    </span>
                  </p>
                )}

                <div
                  className={`flex ${
                    message.sender_id === user.id ? 'justify-end' : 'justify-start'
                  } ${isPreviousMessageBySameUser ? 'pt-0.5' : 'pt-4'}`}
                >
                  <div
                    className={`rounded-2xl px-2 py-2 max-w-[75%] min-w-[120px] break-words ${
                      message.sender_id === user.id
                        ? 'bg-blue-700 text-white rounded-br-none dark:bg-slate-800'
                        : 'bg-gray-200 text-black rounded-tl-none cursor-pointer'
                    }`}
                    onClick={() => handleReply(message.id)}
                    onTouchStart={(e) => handleTouchStart(e, message.id)}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    style={{
                      transform: `translateX(${activeMessageId === message.id ? dragOffset : 0}px)`,
                      transition: dragOffset === 0 ? 'transform 0.2s ease' : '',
                    }}
                  >
                    {showReplyIndicator && activeMessageId === message.id && (
                      <div className="absolute left-[-30px] top-1/2 transform -translate-y-1/2 text-blue-700 dark:text-gray-300 py-1 rounded shadow text-sm">
                        <FaReply />
                      </div>
                    )}

                    {message.reply_to && (
                      <div
                        className={`${
                          message.sender_id === user.id
                            ? 'dark:text-blue-500 dark:bg-slate-700 bg-blue-600 dark:border-gray-300 border-blue-400 text=gray-50'
                            : 'bg-gray-300 border-blue-500 dark:bg-slate-600 dark:border-slate-800 '
                        }  border-l-4 pl-2 py-1 text-xs rounded-[5px] flex-col flex mb-[1px]`}
                      >
                        <span className="dark:text-gray-300 font-medium">
                          {messages.find((msg: any) => msg.id === message.reply_to)?.sender_id ===
                          user.id
                            ? 'You'
                            : selectedUser.name}
                        </span>
                        <span className="dark:text-gray-400">
                          {truncateText(
                            messages.find((msg: any) => msg.id === message.reply_to)?.message,
                            50,
                          )}
                        </span>
                      </div>
                    )}

                    <p className="px-1 text-sm">{message.message}</p>
                    <p className="text-end p-1 text-[10px]">
                      {formatTimestamp(message.created_at, 'time')}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Input Area */}
      <div className="bg-hc_F4EBFA dark:bg-slate-700 p-2 flex items-center fixed bottom-0 left-0 right-0">
        <div className="relative flex-1">
          <input
            autoComplete="off"
            // ref={inputRef}
            id="myInput"
            type="text"
            className={`w-full bg-gray-100 dark:bg-slate-800 px-4 text-black py-2 rounded-full shadow-sm focus:outline-none dark:text-gray-300 h-[45px] ${
              replyTo && 'pr-10 placeholder-custom-600 dark:placeholder-white'
            }`}
            placeholder={
              replyTo
                ? `Replying: ${truncateText(
                    messages.find((msg: any) => msg.id === replyTo)?.message,
                    15,
                  )}`
                : 'Type a message...'
            }
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && sendMessage(e)}
          />
          {replyTo && (
            <button
              type="button"
              className="absolute top-3 right-3"
              onMouseDown={() => {
                setReplyTo(null);
                inputRef.current?.focus();
              }}
              tabIndex={-1}
            >
              <FaX className="text-red-600 bg-white p-1 rounded-full text-lg" />
            </button>
          )}
        </div>

        <button
          type="button"
          className="ml-1 flex items-center justify-center bg-custom-600 text-white rounded-full h-[45px] w-[45px] shadow hover:bg-blue-700"
          onMouseDown={(e) => sendMessage(e)}
          tabIndex={-1}
        >
          <LuSendHorizonal className="text-2xl" />
        </button>
      </div>
    </div>
  );
};

export default Chat;
