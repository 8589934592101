import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FeedsQueryKeys } from './feeds.keys';
import { PostInterface } from '../../app-schemas/posts/post.schema';
import apiClient from '../ApiClient';

// API call to like a feed post by its ID
const likeFeedById = async (id: number): Promise<any> => {
  return await apiClient.post('react', { community_post_id: id });
};

export const useLikeFeedById = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: likeFeedById,

    onMutate: async (post_id: number) => {
      // Cancel ongoing fetches to prevent overriding the optimistic update
      await queryClient.cancelQueries({ queryKey: FeedsQueryKeys.all });

      // Retrieve the current cached data for the feed
      const previousData: any = queryClient.getQueryData(FeedsQueryKeys.all);

      if (!previousData) return { previousData };

      // Optimistically update the post reactions
      const updatedPages = previousData?.pages.map((page: any) => {
        const updatedPosts = page?.data.map((post: PostInterface) => {
          if (post.post_id === post_id) {
            return {
              ...post,
              reacted_to_post: !post.reacted_to_post,
              reactions: post.reacted_to_post ? post.reactions - 1 : post.reactions + 1,
            };
          }

          return post;
        });

        return { ...page, data: updatedPosts };
      });

      const newPagesData = {
        ...previousData,
        pages: updatedPages,
      };

      queryClient.setQueryData(FeedsQueryKeys.all, newPagesData);

      // Return the previous data for rollback if needed
      return { previousData };
    },
    onError: (error, _, context: any) => {
      console.log(error);
      // Rollback to the previous data on error
      if (context?.previousData) {
        queryClient.setQueryData(FeedsQueryKeys.all, context.previousData);
      }
    },
  });
};
