import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../components/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/tabs';
import { Avatar, CommunityImage, Loader } from '../../assets/custom-icons/index';
import { SITELINK } from '../../app-routes/Links';
import {
  LuArrowLeft,
  LuCamera,
  LuImage,
  LuMic,
  LuPause,
  LuPlay,
  LuTrash2,
  LuX,
} from 'react-icons/lu';
import {
  WhatsappImage,
  WeChatImage,
  TelegramImage,
  MessageImage,
} from '../../assets/custom-icons/index';
import { Link, useParams } from 'react-router-dom';
import { useCommunityDetails } from '../../api-services/community/use-community-details';
import { useCommunityMember } from '../../api-services/community/use-community-members';
// import { useCommunityActivity } from '../../api-services/community/use-community-activity';
import { Community, Member } from '../../app-schemas/community/community.interface';
import AppLayout from '../../layouts/AppLayout';
import PostCard from '../home/home-components/PostCard';
import { FeedsQueryKeys } from '../../api-services/feeds/feeds.keys';
import { fetchFeeds } from '../../api-services/feeds/useFetchNewFeeds';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PostInterface } from '../../app-schemas/posts/post.schema';
import { MultiSelect, Option } from 'react-multi-select-component';
import { useUserCommunities } from '../../api-services/community/useUserCommunities';
import { getFirstLetters, getUser, tinyAlert } from '../../app-utils';
import feeds_api from '../../api-services/feeds/feeds.services';
import Modal from '../../components/modal';
import ButtonComponent from '../../components/ButtonComponent';
import { UserInterface } from '../../app-schemas/profile.schema';

const CommunityDetails = () => {
  document.body.classList.remove('overflow-hidden');
  const { id } = useParams();

  const user: UserInterface = getUser();
  // const navigate = useNavigate();

  const { ref, inView } = useInView();
  const userCommunityDetails = useCommunityDetails();
  const members = useCommunityMember();

  // const activity = useCommunityActivity();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [userCommunitiesOptions, setUserCommunitiesOptions] = useState<Option[]>([]);
  const [communityID, setCommunityID] = useState<Option[]>([]);
  const [selectedCommunities, setSelectedCommunities] = useState<string[]>([id ?? '']);
  const [textContent, setTextContent] = useState<string>('');
  const [isRecording, setIsRecording] = useState(false);

  const [audioBlob, setAudio] = useState<string | Blob>('');
  const [isPaused, setIsPaused] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const startTimeRef = useRef<number | null>(null);
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const [multipleFile, setMultipleFile] = useState<File[] | null>(null);
  const [errmessage, setErrmessage] = useState('');
  const [errmessageII, setErrmessageII] = useState('');
  const [hashtag, setHashtag] = useState('');
  const [images, setImages] = useState<string[]>([]); // State to store image URLs
  // react-query area
  const userCommunities = useUserCommunities();

  /* side Effect area */
  useEffect(() => {
    if (userCommunities.isSuccess) {
      const options = userCommunities?.data?.data?.data?.map((option: Community) => ({
        label: `${option.name}`,
        description: `${option.city}, ${option.country}`,
        value: option.id,
      }));

      setUserCommunitiesOptions(options);
      setCommunityID(options);
      setErrmessage('');
      setSelectedCommunities([id ?? '']);
    }
  }, [userCommunities.isSuccess, userCommunities.data, id]);

  const handleRecord = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      tinyAlert('error', 'Your browser does not support audio recording.');
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorderRef.current = mediaRecorder;

    mediaRecorder.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, {
        type: 'audio/mpeg',
      });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioURL(audioUrl);
      setAudio(audioBlob);
      // sessionStorage.setItem('audioURL', audioBlob ?? "")
      audioChunksRef.current = [];
    };

    if (!isPaused) {
      setIsRecording(true);
      setRecordingTime(0);
      startTimeRef.current = Date.now();
    }

    mediaRecorder.start();
    setIsPaused(false);

    // Start recording time counter
    timerRef.current = setInterval(() => {
      setRecordingTime(Math.floor((Date.now() - startTimeRef.current!) / 1000));
    }, 1000);
  };
  const handleStop = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsPaused(false);

      if (audioPlayerRef.current) {
        audioPlayerRef.current.pause();
        audioPlayerRef.current.currentTime = 0;
      }
    }
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (audioURL) {
      console.log('Audio URL:', audioURL);
      // Send the audio file to a server or handle it as needed
    }
  };
  const handlePauseResume = () => {
    if (isRecording) {
      if (!isPaused) {
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current.pause();
          setIsPaused(true);
          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
          if (audioPlayerRef.current) {
            audioPlayerRef.current.pause();
            audioPlayerRef.current.currentTime = 0;
          }
        }
      } else {
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current.resume();
          setIsPaused(false);
          startTimeRef.current = Date.now() - recordingTime * 1000;
          timerRef.current = setInterval(() => {
            setRecordingTime(Math.floor((Date.now() - startTimeRef.current!) / 1000));
          }, 1000);
        }
      }
    }
  };
  const handleDiscard = () => {
    setAudioURL(null);
    setRecordingTime(0);
    setIsRecording(false);
    setIsPaused(false);
    sessionStorage.removeItem('audioURL');
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
    queryKey: FeedsQueryKeys.all,
    queryFn: fetchFeeds,
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.currentPage < lastPage.totalPages ? lastPage.currentPage + 1 : undefined,
  });

  const content = useMemo(
    () =>
      data?.pages.flatMap((page) =>
        page.data.map((post: PostInterface, index: number) => (
          <PostCard
            key={post.post_id}
            index={post.post_id}
            post={post}
            innerRef={page.data.length - 2 === index + 1 ? ref : undefined}
          />
        )),
      ),
    [data, ref],
  );

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  const chatOnWhatsapp = (number: number | null) => {
    const url = `https://wa.me/${number}`;
    window.open(url, '_blank'); // Open in a new tab
  };
  const handleTelegramClick = (number: number | null) => {
    const url = `tg://openchat?phone=${number}`;
    window.open(url, '_blank'); // Open in a new tab
  };

  // Function to handle the creation of an image
  const createImageII = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && typeof e.target.result === 'string') {
        // Add the image to the state
        setImages((prevImages) => [...prevImages, e.target?.result as string]);
      }
    };

    reader.readAsDataURL(file);
  };

  // Function to handle image deletion
  const deleteImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setMultipleFile(null);
  };

  const handleFileChange = (value: FileList | null) => {
    if (value !== null && value?.length > 0) {
      setMultipleFile(null);
      setImages([]);

      const filesArray = Array.from(value);
      setMultipleFile(filesArray);
      filesArray.forEach((file) => {
        // createImage(file);
        createImageII(file);
      });
    }
  };

  // const handleContinue = (e: any) => {
  //   e.preventDefault();
  //   openModal
  //     ? document.body.classList.remove('overflow-hidden')
  //     : document.body.classList.add('overflow-hidden');

  //   textContent.length === 0
  //     ? setErrmessage('Kindly write something to continue')
  //     : setErrmessage('');
  //   textContent.length > 0 && setOpenModal(true);
  // };

  const handleCloseModal = () => {
    document.body.classList.remove('overflow-hidden');
    setOpenModal(!openModal);
  };
  // const handleCheckboxChange = (value: string) => {
  //   setSelectedCommunities((prevSelected) => {
  //     if (prevSelected.includes(value)) {
  //       // If the value is already selected, remove it
  //       return prevSelected.filter((item) => item !== value);
  //     } else {
  //       // Otherwise, add it to the selected items
  //       return [...prevSelected, value];
  //     }
  //   });
  // };

  /** create post */
  const handleCreateNewPost = async () => {
    setErrmessageII('');
    if (selectedCommunities.length === 0) {
      setErrmessageII('Select at least one community');
      return;
    }

    setLoading(true);

    let data = {
      images: multipleFile,
      text_content: hashtag + ' ' + textContent,
      audio: audioBlob,
      selected_community: selectedCommunities,
    };

    try {
      await feeds_api.CreateNewFeed(data);
      // navigate(SITELINK.HOME);
    } catch (error) {
      tinyAlert('error', 'An error occured!');
    } finally {
      document.body.classList.remove('overflow-hidden');
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <AppLayout title="Catapot - Home">
        <div className="flex py-5 justify-center items-center h-[90vh]">
          <img src={Loader} alt="loader" className="h-[20px] w-[20px]" />
        </div>
      </AppLayout>
    );
  }

  const renderProfilePicture = () =>
    user.profile_picture_url ? (
      <img
        alt="profile"
        src={user.profile_picture_url}
        className="object-cover rounded-full w-[40px] h-[40px] bg-gray-200 shadow-sm"
      />
    ) : (
      <div className="block w-[40px] h-[40px] bg-[#223f64] rounded-full border border-green-100 flex items-center justify-center">
        <h3 className="text-white text-[1.4rem] uppercase font-[600]">
          {getFirstLetters(user.first_name ?? user.username)}
        </h3>
      </div>
    );

  return (
    <AppLayout title="Catapot - Community Details">
      <div className="flex justify-between px-3 py-3 bg-white text-custom-600 hidden">
        <div className="flex gap-4">
          <Link to={SITELINK.COMMUNITIES} className="font-semibold text-sm flex mt-[5px]">
            <LuArrowLeft className="text-xl" />
          </Link>

          <p className="mt-1 text-sm font-medium">Community Details</p>
        </div>
      </div>

      {/* cover image area */}
      <section className="relative flex justify-center items-center overflow-hidden bg-cover bg-center max-h-[150px] hidden">
        {userCommunityDetails.isSuccess && (
          <div>
            {userCommunityDetails.data.data.cover_image_url === '' ? (
              <img src={CommunityImage} className="w-full" alt="cover" />
            ) : (
              <img
                src={userCommunityDetails.data.data.cover_image_url}
                className="w-full"
                alt="cover"
              />
            )}
          </div>
        )}
      </section>

      {/* content area */}
      <section className="bg-white mt-1 border shadow-sm">
        <div className="min-h-screen py-5 rounded-md bg-white flex flex-col gap-2 lg:gap-3 lg:w-[90%] mx-auto box-border">
          {userCommunityDetails.isSuccess && (
            <>
              <div className=" rounded-md bg-white flex flex-col gap-2 lg:gap-3 lg:w-[80%] mx-auto box-border">
                {/* <img src={CommunityImage} className="w-[52px] h-[52px] rounded-full" alt="" /> */}

                <h2 className="text-xl font-bold px-3">{userCommunityDetails.data.data.name}</h2>
                <p className="lg:w-[480px] px-3 font-medium text-sm">
                  created: {userCommunityDetails.data.data.created}
                </p>

                <p className="lg:w-[480px] px-3 font-medium text-sm">
                  {userCommunityDetails.data.data.description
                    ? userCommunityDetails.data.data.description
                    : 'N/A'}
                </p>

                <div className="flex gap-5 flex-wrap items-center">
                  {userCommunityDetails.data.data?.community_tags?.map(
                    (tag: any, index: number) => (
                      <button
                        key={index}
                        className="flex h-10 items-center px-4 text-sm  transition-colors  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100  aria-disabled:opacity-50 cursor-pointer w-[96px]  justify-center border border-orange-100 rounded-sm bg-white font-bold text-orange-100"
                      >
                        {tag}
                      </button>
                    ),
                  )}
                </div>

                {/* input area */}
                <div className="composer-area px-3 hidden">
                  <textarea
                    value={textContent}
                    placeholder="Have something to say?"
                    className="text-sm font-[500] p-3 w-full border border-gray-300 shadow-sm rounded-lg"
                    rows={1}
                    onChange={(e) => setTextContent(e.target.value)}
                  ></textarea>

                  {/* Container for displaying images */}
                  <div
                    id="imageContainer"
                    style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
                  >
                    {images.map((imageSrc, index) => (
                      <div
                        key={index}
                        style={{ position: 'relative', width: '100px', height: '100px' }}
                      >
                        <img
                          src={imageSrc}
                          alt={`Uploaded ${index}`}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '5px',
                          }}
                        />
                        {/* Delete icon */}
                        <span
                          onClick={() => deleteImage(index)}
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            backgroundColor: '#bbb4b4',
                            color: 'red',
                            borderRadius: '50%',
                            cursor: 'pointer',
                            padding: '3px 3px',
                          }}
                        >
                          <LuX />
                        </span>
                      </div>
                    ))}
                  </div>

                  {/* <div id="imageContainer" className="px-2 flex flex-wrap gap-2 mb-2 rounded-lg"></div> */}

                  {audioURL && (
                    <audio
                      controls
                      src={audioURL}
                      className="bg-white w-full custom-audio-player mb-1"
                    ></audio>
                  )}
                </div>

                {/* upload attacment area */}
                <div className="flex justify-between px-5 mt-1 hidden">
                  {/* add image area */}
                  <label
                    htmlFor="dropzone-file"
                    className="text-sm flex py-1 px-4 rounded-full shadow-sm gap-1 cursor-pointer"
                  >
                    <LuImage className="text-lg text-custom-600" />{' '}
                    <span className="text-custom-600 font-medium">Add image</span>
                    <input
                      id="dropzone-file"
                      ref={fileInputRef}
                      type="file"
                      accept=".png, .jpeg, .jpg"
                      onChange={(e) => handleFileChange(e.currentTarget.files)}
                      className="hidden"
                    />
                  </label>

                  <span className="border-r border-gray-400 border-1 m-1"></span>

                  <div className="">
                    <div className="controls flex items-center gap-3">
                      {!isRecording && !audioURL && (
                        <button
                          onClick={handleRecord}
                          className="text-sm flex py-1 px-4 rounded-full shadow-sm gap-1 cursor-pointer"
                          title="Start Recording"
                          disabled={isRecording}
                        >
                          <LuMic className="text-lg text-custom-600" />
                          <span className="text-custom-600 font-medium">Voice Note </span>
                        </button>
                      )}
                    </div>

                    <div className="recording-status flex  w-[50%] md:w-full items-center gap-4">
                      {(isRecording || audioURL) && (
                        <button
                          onClick={handleDiscard}
                          title="Discard Recording"
                          className="mt-[5px]"
                        >
                          <LuTrash2 className="text-lg text-red-700 font-bold" strokeWidth={1} />
                        </button>
                      )}

                      {isRecording && (
                        <span className="text-custom-600 font-medium">
                          {new Date(recordingTime * 1000).toISOString().substr(14, 5)}
                        </span>
                      )}

                      {isRecording && (
                        <button
                          onClick={handlePauseResume}
                          disabled={!isRecording}
                          title={isPaused ? 'Resume' : 'Pause'}
                        >
                          {isPaused ? (
                            <LuMic className="text-custom-600 font-medium" strokeWidth={1} />
                          ) : (
                            <LuPause className="text-custom-600 font-medium" strokeWidth={1} />
                          )}
                        </button>
                      )}
                      {isRecording && (
                        <button onClick={handleStop} disabled={!isRecording} title="Stop Recording">
                          <LuPlay className="text-custom-600 font-medium" strokeWidth={1} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {/* hash-tags section */}
                <div className="px-5 hidden">
                  <div className="pt-2 mt-2 flex gap-1 overflow-x-scroll pb-1">
                    <button
                      type="button"
                      className={`text-xs flex py-2 gap-1 rounded-full px-4 border font-semibold whitespace-nowrap text-custom-600 ${
                        hashtag === '#Jobs' ? 'bg-blue-700 text-white' : 'bg-gray-100'
                      }`}
                      onClick={() => setHashtag('#Jobs')}
                    >
                      #Jobs
                    </button>

                    <button
                      type="button"
                      className={`text-xs flex py-2 gap-1 rounded-full px-4 border font-semibold whitespace-nowrap text-custom-600 ${
                        hashtag === '#Sharerides' ? 'bg-blue-700 text-white' : 'bg-gray-100'
                      }`}
                      onClick={() => setHashtag('#Sharerides')}
                    >
                      #Sharerides
                    </button>

                    <button
                      type="button"
                      className={`text-xs flex py-2 gap-1 rounded-full px-4 border font-semibold whitespace-nowrap text-custom-600 ${
                        hashtag === '#Events' ? 'bg-blue-700 text-white' : 'bg-gray-100'
                      }`}
                      onClick={() => setHashtag('#Events')}
                    >
                      #Events
                    </button>
                  </div>
                </div>

                <div className="px-5 hidden">
                  <div className="flex gap-1 overflow-x-scroll pb-4">
                    <button
                      type="button"
                      className={`text-xs flex py-2 gap-1 rounded-full px-4 border font-semibold whitespace-nowrap text-custom-600 ${
                        hashtag === '#Marketplace' ? 'bg-blue-700 text-white' : 'bg-gray-100'
                      }`}
                      onClick={() => setHashtag('#Marketplace')}
                    >
                      #Marketplace
                    </button>

                    <button
                      type="button"
                      className={`text-xs flex py-1 gap-1 bg-red-600 rounded-full px-2 border font-semibold whitespace-nowrap text-white`}
                      onClick={() => setHashtag('')}
                    >
                      <span className="text-text-600 font-medium text-lg mt-[2px]">
                        <LuTrash2 />
                      </span>
                    </button>
                  </div>
                </div>

                <div className="px-5 mb-5 hidden">
                  <p className="text-red-600 text-xs text-center">
                    {textContent.length === 0 && errmessage}
                  </p>
                  <p className="text-red-600 text-xs text-center">
                    {selectedCommunities.length === 0 && errmessageII}
                  </p>
                  <button
                    type="button"
                    // onClick={handleContinue}
                    onClick={() => handleCreateNewPost()}
                    className="w-full text-sm flex py-1 gap-1 justify-center disabled:bg-gray-400 text-white rounded-xl px-5 border font-medium py-3 bg-blue-500"
                    disabled={loading}
                  >
                    {loading ? 'Posting...' : 'Post'}
                  </button>
                </div>

                <Tabs defaultValue="activity" className="w-full">
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="activity">Activities</TabsTrigger>
                    {/* <TabsTrigger value="about">About Group</TabsTrigger> */}
                    <TabsTrigger value="member">
                      <span className="bg-gray-100 text-blue-600 px-2 rounded-full">
                        {' '}
                        {members?.data?.data?.community_members?.length}
                      </span>{' '}
                      &nbsp;&nbsp; Members{' '}
                    </TabsTrigger>
                  </TabsList>

                  <TabsContent value="about">
                    <Card>
                      <div className="flex flex-col lg:flex-row">
                        <div>
                          <CardHeader>
                            <CardTitle className="text-[#0A32AA]">Description</CardTitle>
                            <CardDescription className="lg:w-[480px]">
                              {userCommunityDetails.data.data.description
                                ? userCommunityDetails.data.data.description
                                : 'N/A'}
                            </CardDescription>
                          </CardHeader>
                          <CardContent className="space-y-2">
                            <CardTitle className="text-[#0A32AA]">Community Tag</CardTitle>
                            <div className="flex gap-5 flex-wrap items-center">
                              {userCommunityDetails.data.data?.community_tags?.map(
                                (tag: any, index: number) => (
                                  <button
                                    key={index}
                                    className="flex h-10 items-center px-4 text-sm  transition-colors  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100  aria-disabled:opacity-50 cursor-pointer w-[96px]  justify-center border border-orange-100 rounded-sm bg-white font-bold text-orange-100"
                                  >
                                    {tag}
                                  </button>
                                ),
                              )}
                            </div>
                          </CardContent>
                        </div>

                        <div>
                          <CardHeader className="flex flex-col gap-2">
                            <CardTitle className="w-full text-[#0A32AA]">
                              Community Information
                            </CardTitle>
                            <div className="flex flex-col gap-10">
                              <CardDescription className="w-full flex justify-between items-center">
                                <div className="flex gap-1 items-center">
                                  <img src={Avatar} className="w-[32px] h-[32px]" alt="" />
                                  <p className="text-xs">
                                    {userCommunityDetails.data?.data?.creator?.username ??
                                      userCommunityDetails.data?.data?.creator?.first_name}
                                  </p>
                                </div>
                                <p>Creator</p>
                              </CardDescription>
                              <CardDescription className="w-full flex justify-between items-center">
                                <p>Members</p>
                                <p>{userCommunityDetails.data.data.members}</p>
                              </CardDescription>
                              <CardDescription className="w-full  flex justify-between items-center">
                                <p>Created</p>
                                <p>{userCommunityDetails.data.data.created}</p>
                              </CardDescription>
                            </div>
                          </CardHeader>
                        </div>
                      </div>
                    </Card>
                  </TabsContent>

                  <TabsContent value="activity">
                    {/* {activity?.data?.data?.length > 0 &&
                      activity?.data?.data?.map((post: any, index: number) => (
                        <PostCard key={index} index={index} post={post} />
                      ))} */}

                    <section className="mb-20">
                      <div className="relative bg-white border-t border-gray-300 px-2 py-2 flex gap-1 item-center justify-center">
                        <Link to={SITELINK.MY_PROFILE} className="w-[50px] block">
                          {renderProfilePicture()}
                        </Link>

                        <Link
                          to={`${SITELINK.COM_COMPOSER}/${id}`}
                          className="text-sm p-2 px-4 w-full border-gray-300 text-start text-gray-600 font-[400] border rounded-[50px]"
                        >
                          Have something to say?
                        </Link>

                        <Link
                          to={SITELINK.COMPOSER}
                          className="text-[1.4rem] text-gray-500 absolute right-[18px] top-[12px] bg-white rounded-[50px] bg-white p-1 rounded-full"
                        >
                          <LuCamera />
                        </Link>
                      </div>

                      <div className="text-sm">{content}</div>

                      {isFetchingNextPage && (
                        <div className="flex py-5 justify-center">
                          <img src={Loader} alt="loader" className="h-[20px] w-[20px]" />
                        </div>
                      )}

                      {!hasNextPage && (
                        <div className="text-xs flex justify-center pt-5">No content</div>
                      )}
                    </section>
                  </TabsContent>

                  <TabsContent value="member">
                    <div className="flex flex-col gap-2 sm:w-[80%] mx-auto lg:w-auto mb-[100px]">
                      {members.isSuccess &&
                        members?.data?.data?.community_members?.map(
                          (member: Member, index: number) => (
                            <Card key={index} className="p-3 rounded-none bg-[#BEC2CE29]">
                              <div className="flex   gap-4 justify-between items-center">
                                <div className="flex gap-1 items-center">
                                  <div className="mb-2 flex items-center gap-3">
                                    <img
                                      src={
                                        !!member.profile_picture ? member.profile_picture : Avatar
                                      }
                                      className="w-[32px] h-[32px] rounded-full"
                                      alt=""
                                    />
                                    <div className="text-xs">
                                      <p>{member.username}</p>
                                      <p>
                                        {member.name ===
                                        userCommunityDetails.data?.data?.creator?.username
                                          ? 'Creator'
                                          : 'Member'}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="flex lg:justify-between gap-4 lg:gap-16 items-center">
                                  <img
                                    src={WhatsappImage}
                                    alt=""
                                    className="cursor-pointer"
                                    onClick={() => chatOnWhatsapp(member.whatsapp_number)}
                                  />
                                  <img
                                    src={TelegramImage}
                                    alt=""
                                    className="cursor-pointer"
                                    onClick={() => handleTelegramClick(member.phone_number)}
                                  />
                                  <img src={WeChatImage} alt="" />

                                  <Link
                                    to={SITELINK.CHATIN}
                                    onClick={() => {
                                      localStorage.setItem(
                                        'lastChattedUserInfo',
                                        JSON.stringify({
                                          id: member?.user_id,
                                          name: member.username,
                                          image: member.profile_picture,
                                        }),
                                      );
                                    }}
                                  >
                                    <img src={MessageImage} alt="" />
                                  </Link>
                                </div>
                              </div>
                            </Card>
                          ),
                        )}
                    </div>
                  </TabsContent>
                </Tabs>
              </div>
            </>
          )}
        </div>
      </section>

      <Modal isOpen={openModal} onClose={handleCloseModal}>
        <section className="p-3 w-[90vw]">
          <p className="hidden text-center font-semibold text-lg">Select communities</p>
          <p className="hidden text-center font-medium text-xs mt-1 text-gray-500 mb-3">
            Choose the communities you want this post to be published into.
          </p>

          <div className="px-2 py-2 border border-dotted border-gray-400 rounded-lg mb-3">
            <MultiSelect
              options={userCommunitiesOptions}
              value={communityID}
              onChange={setCommunityID}
              labelledBy="community-select-label"
              className="text-xs font-medium mb-2 hidden"
              overrideStrings={{
                allItemsAreSelected: 'Post to all communities',
                selectSomeItems: 'Select some community...',
                search: 'Search community',
                clearSearch: 'Clear search',
                clearSelected: 'Clear selected',
              }}
            />

            <div className="border p-2 mb-3 text-medium">
              <label className="border-b font-semibold text-lg w-full p-2 flex">
                Select communities
              </label>
              {/* <div
                id="community"
                className="overflow-y-scroll h-[200px] p-1 mb-3 text-xs font-medium"
              >
                {userCommunitiesOptions.map((item: any, index: number) => (
                  <div key={index} className="border-t p-1">
                    <input
                      type="checkbox"
                      id={`community_${index}`}
                      name="community"
                      value={item.value}
                      onChange={() => handleCheckboxChange(item.value)}
                      checked={selectedCommunities.includes(item.value)}
                    />
                    <label className="pl-2" htmlFor={`community_${index}`}>
                      {truncateText(item.label, 25)}
                    </label>
                  </div>
                ))}
              </div> */}
            </div>

            <p className="text-red-600 text-xs text-center">
              {selectedCommunities.length === 0 && errmessageII}
            </p>

            <ButtonComponent
              type="button"
              onClick={() => handleCreateNewPost()}
              disabled={loading}
              loading={loading}
              className="text-sm flex py-1 gap-1 justify-center bg-custom-600 text-white rounded-lg px-5 border font-medium py-3"
            >
              Post
            </ButtonComponent>
          </div>
        </section>
      </Modal>
    </AppLayout>
  );
};
export default CommunityDetails;
