import { useQuery } from "@tanstack/react-query";
import apiClient from "../ApiClient";

const getConversation = async (receiverId: number) => {
  const res = await apiClient.get(`chat/conversation/${receiverId}`);
  return res.data;
};

export function useConversation(receiverId: number) {
  return useQuery({
    queryKey: ["conversation", receiverId], // 'conversation' as the key for better understanding
    queryFn: () => getConversation(receiverId), // Passing receiverId to the function
    enabled: !!receiverId, // Make sure query runs only when receiverId exists (non-falsy)
    staleTime: 1000 * 60 * 0.5, // Optional: Cache data for 5 minutes
    //refetchOnWindowFocus: true, // Optional: avoid refetching when window focus changes
    refetchInterval: 1000 * 60 * 0.5,
    refetchIntervalInBackground: false,
  });
}
