import {useQuery } from '@tanstack/react-query';
import apiClient from '../ApiClient';

const getInbox = async () => {
  const res = await apiClient.get("chat/inbox");
  return res.data;
};

export function useInbox() {
  return useQuery({
    queryKey: ['all_inbox'],
    queryFn: getInbox,
    staleTime: 1000 * 60 * 2,
    refetchInterval: 1000 * 60 * 2,
  });
}