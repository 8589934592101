import { Link } from "react-router-dom";
import { useInbox } from "../../../api-services/messaging/useInbox";
import { SITELINK } from "../../../app-routes/Links";
import { UserProfileInterface } from "../../../app-schemas/user.interface";
import { formatTimestampToRelativeTime, getUser, truncateText } from "../../../app-utils";
import Preloader from "../../../components/preloader/Preloader";
import AppLayout from "../../../layouts/AppLayout";

const Inbox: React.FC<{
  selectUser: (userId: number, name: string, image: string) => void;
}> = ({ selectUser }) => {
  const user: UserProfileInterface = getUser();
  const inbox = useInbox();

  return (
    <AppLayout>
      <div className="bg-hc_F4EBFA dark:bg-slate-700 min-h-[82vh] mt-[0px]">
        <div className="p-2 space-y-2">
          {inbox?.isLoading && <Preloader loading={true} />}

          {inbox?.isFetched &&
            inbox?.data?.map((item: any, index: number) => (
              <Link
                to={`${SITELINK.CHATIN}`}
                key={index}
                className="flex items-center justify-between bg-white p-4 shadow rounded-lg cursor-pointer dark:bg-slate-800 hover:bg-blue-50"
                onClick={() => {
                  selectUser(
                    item.receiver?.id === user.id
                      ? item.sender?.id
                      : item.receiver?.id,

                    item.receiver?.id === user.id
                      ? item.sender?.username
                      : item.receiver?.username,

                    item.receiver?.id === user.id
                      ? item.sender?.profile_picture_url
                      : item.receiver?.profile_picture_url
                  );
                }}
              >
                <div className="flex gap-3">
                  <div className="h-[50px] w-[50px]">
                    <img
                      src={
                        item.receiver?.id === user.id
                          ? item.sender?.profile_picture_url
                          : item.receiver?.profile_picture_url
                      }
                      alt="mine"
                      className="h-[50px] w-[50px] rounded-lg"
                    />
                  </div>

                  <div>
                    <h3 className="font-semibold dark:text-gray-300">
                      {item.receiver?.id === user.id
                        ? item.sender?.username
                        : item.receiver?.username}
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {truncateText(item.message, 17)}
                    </p>
                  </div>
                </div>
                <div>
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    {formatTimestampToRelativeTime(item.created_at)}
                  </span>
                </div>
              </Link>
            ))}

          {inbox?.isFetched && inbox?.data?.length === 0 && (
            <div className="text-center text-gray-500 dark:text-gray-200 dark:bg-slate-700 mt-[25vh]">
              <p className="mb-5 text-sm">No chat history</p>
              <Link
                className="bg-blue-600 dark:bg-slate-800 dark:text-gray-300 py-2 px-5 border text-white shadow rounded-lg"
                to={SITELINK.HOME}
              >
                Back
              </Link>
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default Inbox;
