export enum SITELINK {
  LOGIN = '/login',
  GOOGLE_LOGIN = '/auth/google/callback',
  SIGNUP = '/sign-up',
  AUTH_2FA = '/auth-2fa',
  REQUEST_RESET = '/request-reset',
  RESET_PASSWORD = '/reset-password',

  PROFILE_SETUP = '/profile-setup',

  HOME = '/home',
  COMPOSER = '/home/compose',
  COM_COMPOSER = '/community/compose',
  APP_SEARCH = '/search',
  NOTIFICATIONS = '/notifications',
  BOOKMARKS = '/saved-posts',
  MY_POSTS = '/my-posts',
  LIKED_POSTS = '/liked-posts',
  VIEW_POSTS = '/view-post',

  PRIVACY_AND_SETTINGS = '/privacy-and-settings',

  SUBSCRIPTIONS = '/premium-services',
  CHECKOUT = '/subscription-checkout',
  MY_SUBSCRIPTION_HISTORY = '/my-subscriptions-history',

  MY_PROFILE = '/me',
  BADGE_VERIFICATION = '/badge-account-verification',

  NEW_COMMUNITIES = '/communities/create',
  COMMUNITIES = '/communities',
  MORE_COMMUNITIES = '/view-more-communities',
  COMMUNITIES_SEARCH = '/communities/search',
  COMMUNITY_ABOUT = '/communities/about',
  COMMUNITY_ACTIVITIES = '/communities/activities',
  COMMUNITY_MEMBERS = '/communities/members',

  MESSAGES = '/messages',
  VIEW_MESSAGES = '/message/view',
  FIND_MESSAGES = '/message/search',

  CHAT = "/messaging",
  CHATIN = "/chat",
}
