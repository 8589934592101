import { FormEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonComponent from '../../components/ButtonComponent';
import usePreventDefault from '../../custom-hooks/PreventDefault';
import AuthLayout from '../../layouts/AuthLayout';
import { MembersLoginType, MembersRequestResetType } from '../../app-schemas/authentication.schema';
import { SITELINK } from '../../app-routes/Links';
import auth from '../../api-services/auth/authentication.services';
import { decryptDataWithIV, storeToken, storeUser } from '../../app-utils';
import { FEED_MSSG, RES_MSSG } from '../../api-services/ConstMssg';
import OTPInput from 'react-otp-input';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const __PDef = usePreventDefault();
  const [loading, setLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [password, setSecret] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState(150);
  const [resendCode, setResendCode] = useState<boolean>(true);

  // handle otp input
  const handleOTPChange = (otp: any) => {
    setOtp(otp);
  };

  useEffect(() => {
    const secret = localStorage.getItem('forVerify') || '';

    if (!!secret) {
      const secret_reveal = decryptDataWithIV(secret);
      const loginData = JSON.parse(secret_reveal);
      setEmail(loginData.email);
      setSecret(loginData.password);
    } else {
      // setErrorMsg('session expired, try again');
      navigate(SITELINK.LOGIN);
    }
  }, [navigate]);

  useEffect(() => {
    if (!!email && !isVerified) RequestReset({ email });
  }, [email, isVerified]);

  useEffect(() => {
    if (otp.length === 6) {
      verify2FACode(otp, email);
    }
  }, [otp, email]);

  useEffect(() => {
    if (!!email && isVerified) emailLogin({ email, password }, navigate);
  }, [email, password, isVerified, navigate]);

  useEffect(() => {
    if (timeLeft === 0) {
      setResendCode(false);
    }
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // Clear the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  // Format time into MM:SS
  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  // -- [#]
  const RequestReset = async (data: MembersRequestResetType) => {
    setErrorMsg('');
    // add page prelaoder
    try {
      const res = await auth.RequestReset(data);

      if (res?.data?.message === RES_MSSG.SUCCESS) {
        setSuccessMsg('Verification code was sent to your email address');
      } else {
        setErrorMsg(res?.response?.message);
      }
    } catch (error) {
      setErrorMsg('An error occured!');
    } finally {
      // close page preloader
    }
  };

  // -- [1] VERIFY EMAIL
  const handleVerify2FA = async (e: FormEvent) => {
    __PDef(e);
    setErrorMsg('');
    if (otp.length === 6) {
      await verify2FACode(otp, email);
    } else {
      setErrorMsg(FEED_MSSG.OTP_LENGTH_SHORT);
    }
  };
  const verify2FACode = async (otp: string, email: string) => {
    setLoading(true);
    setErrorMsg('');
    const data = {
      verification_code: otp,
      email: email,
    };

    try {
      const res = await auth.VerifyCode(data);

      if (res?.data?.message === RES_MSSG.VERIFIED) {
        setSuccessMsg(FEED_MSSG.VERIFIED);
        setIsVerified(true);
      } else {
        setErrorMsg(res?.response?.data?.message);
      }
    } catch (error) {
      setErrorMsg('An error occured!');
    } finally {
      setLoading(false);
    }
  };

  // -- [2] USER LOGIN
  const emailLogin = async (data: MembersLoginType, navigate: any) => {
    setLoading(true);

    try {
      const res = await auth.EmailLogin(data);

      if (res?.data?.message === RES_MSSG.SUCCESS) {
        const responseData = res.data;
        const userInfo = responseData.user;

        storeToken(responseData.data);
        storeUser(userInfo);

        localStorage.removeItem('forVerify');

        if (userInfo.is_onboarded) {
          const loginRedirectedFromUrl = localStorage.getItem('loginRedirectedFromUrl');
          localStorage.removeItem('loginRedirectedFromUrl');
          navigate(loginRedirectedFromUrl ?? SITELINK.HOME);
        } else {
          navigate(SITELINK.PROFILE_SETUP);
        }
      } else {
        const message = res?.response?.data?.message;
        switch (message) {
          case RES_MSSG.EMAIL_NOT_VERIFIED:
            setErrorMsg(FEED_MSSG.EMAIL_NOT_VERIFIED);
            break;

          case RES_MSSG.INCORRECT_CREDENTIALS:
            setErrorMsg(FEED_MSSG.INCORRECT_CREDENTIALS);
            break;

          default:
            setErrorMsg('An unexpected error occurred!');
            break;
        }
      }
    } catch (error) {
      setErrorMsg('An error occured!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout title="Verify your email address">
      <section>
        {/* hero-text area */}
        <div className="flex flex-col items-start mt-12">
          <h4 className="text-[1.4rem] font-[800] text-[#181e35]">Verify Email</h4>
          <p className="text-xs font-[500] text-gray-500">
            A verification code was sent to your email address <br />({email})
          </p>
        </div>

        <div className="text-green-600 text-[10px] col-span-full text-center">{successMsg}</div>
        <div className="text-red-600 text-[10px] col-span-full text-center">{errorMsg}</div>

        {/* complete form area */}
        <form onSubmit={handleVerify2FA} className="mt-12 w-full">
          <div className="flex justify-start items-start mb-5">
            <OTPInput
              value={otp}
              onChange={handleOTPChange}
              numInputs={6}
              shouldAutoFocus
              inputStyle={{
                width: '2.8rem',
                height: '2.8rem',
                margin: '0 0.5rem 0 0',
                fontSize: '1.5rem',
                borderRadius: 5,
                border: '1px solid #6d7986',
              }}
              renderInput={(props) => <input {...props} />}
            />
          </div>

          {/* action btn */}
          <div className="mb-6 flex">
            <ButtonComponent
              type="submit"
              className="w-[310px] py-3 rounded-lg text-white bg-custom-600"
              disabled={loading}
              loading={loading}
            >
              Verify email address
            </ButtonComponent>
          </div>
        </form>

        {/* extra reset links */}
        <div>
          {timeLeft > 0 && (
            <p className="text-gray-600 text-sm font-medium text-left mb-1">
              Resend code in {formatTime(timeLeft)}
            </p>
          )}

          <div className="text-start font-light text-xs mt-2 font-medium text-gray-600">
            Did not get the code?{' '}
            <button
              type="button"
              disabled={resendCode}
              className="disabled:text-gray-400 disabled:cursor-not-allowed text-blue-500 text-semibold"
              onClick={() => RequestReset({ email: email })}
            >
              Resend code
            </button>
          </div>
          
          <div className="text-start font-light mb-10 text-xs mt-2 font-medium text-blue-600">
            Go back ? <Link to={SITELINK.LOGIN}>Login here</Link>
          </div>
        </div>
      </section>
    </AuthLayout>
  );
};

export default VerifyEmail;
